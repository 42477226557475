export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/404": [3],
		"/about": [~5],
		"/apps": [~6],
		"/backlog": [~7],
		"/before-and-after": [8],
		"/blog": [~9],
		"/blog/[slug]": [~10],
		"/contact": [11],
		"/developers": [~12],
		"/help": [~13],
		"/hosting-providers": [~14],
		"/legal/acceptable-use-policy": [15],
		"/legal/privacy-policy": [16],
		"/legal/terms-of-service": [17],
		"/multicloud": [18],
		"/open-source": [~19],
		"/testers": [~20],
		"/vs": [~21],
		"/vs/[slug]": [~22],
		"/[slug]": [~4]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';